import React from "react"
import cx from "classnames"
import { Link } from "gatsby"
import loadable from "@loadable/component"

import {
  TITLES,
  SERVICES,
  CLOUDS,
} from "../data/cloud-infrastructure-management"
import Layout from "../components/layout"
import "../assets/styles/commons/_common-kit.scss"
import * as styles from "../assets/styles/cloud-infrastructure-management.module.scss"
import Headline from "../components/headline"

const TitleBlock = loadable(() => import("../components/title-block"))
const ServicesList = loadable(() => import("../components/services-list"))
const ColoredSection = loadable(() => import("../components/colored-section"))
const ContactForm = loadable(() => import("../components/contact-form"))

const CloudInfrastructureManagement = () => (
  <Layout
    pageTitle="Cloud Infrastructure Management Service"
    metaDescription="Maximize efficiency with LaSoft's Cloud Infrastructure Management services, offering expert optimization, security, and scalable solutions."
  >
    {() => {
      return (
        <main className={cx(styles.cloudInfoBlock, "common-kit")}>
          <article>
            <Headline>
              <div className="headline">
                <div className={cx(styles.headline_grid, "headline_grid")}>
                  <p className="strongText">Since 2014, we deliver</p>
                  <h1 className="withIndent">
                    <strong>Cloud infrastructure </strong>
                    management <br />
                    service
                  </h1>
                  <div className="rightColumn">
                    <p>
                      We specialize in crafting customized solutions tailored to
                      the unique requirements of your specific industry.
                    </p>
                    <div>
                      <Link
                        to="#contact-form"
                        className="goldFatLink resizedText cloud-talk"
                      >
                        Talk to expert
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Headline>
            <div className={styles.cloudsBlock}>
              <div className={styles.cloudsWrapper}>
                {CLOUDS.map(item =>
                  item.imageURL ? (
                    <div key={item.id}>
                      <img
                        src={item.imageURL}
                        alt="Cloud"
                        width={item.width}
                        height={item.height}
                        loading="lazy"
                      />
                      {item.text && (
                        <p className={styles.whiteText}>{item.text}</p>
                      )}
                    </div>
                  ) : (
                    <div key={item.id}>
                      <p>{item.text}</p>
                    </div>
                  )
                )}
              </div>
            </div>

            <section className={cx(styles.solutions, "section")}>
              <TitleBlock options={TITLES.solutions} />
              <div className={styles.solutions__tableWrapper}>
                <div className={styles.solutions__table}>
                  <h3>Solution</h3>
                  <div>
                    <ul className={styles.solutions__tableFlex}>
                      <li>Planning</li>
                      <li>Implementation</li>
                    </ul>
                    <div className={styles.solutions__tableSecurity}>
                      <h4>Security</h4>
                      <ul>
                        <li>APP</li>
                        <li>INFRA</li>
                      </ul>
                    </div>
                  </div>
                  <ul>
                    <li>High Availability</li>
                    <li>Governance and Compliance</li>
                    <li>Cost</li>
                    <li>Performance</li>
                  </ul>
                </div>
                <div className={styles.solutions__arrowWrapper}>
                  <div className={styles.solutions__arrow}>
                    Maintenance (Minimum actions required, UP-TO-DATE)
                  </div>
                </div>
              </div>
            </section>

            <section className="section">
              <TitleBlock options={TITLES.services} />
              <ServicesList services={SERVICES.items} />
            </section>

            <ColoredSection options={TITLES.colored} />
          </article>
          <section className="footerForm common-kit">
            <div className="section" id="contact-form">
              <ContactForm formStatID="CommonFooter" />
            </div>
          </section>
        </main>
      )
    }}
  </Layout>
)

export default CloudInfrastructureManagement
