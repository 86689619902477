import React from "react"
import { IMG } from "../const"

export const TITLES = {
  solutions: {
    title: (
      <>
        <strong>
          crafting
          <br /> customized
          <br /> solutions
        </strong>{" "}
        for your specific industry
      </>
    ),
    strongText: "We specialize in",
    uppercase: (
      <>
        Assisting businesses in
        <br /> optimizing their
        <br /> cloud environments
      </>
    ),
    withIndent: true,
  },
  services: {
    title: (
      <>
        we create and design
        <strong> cloud infrastructure </strong>
        and services
      </>
    ),
    strongText: "For startups, small and mid-size companies",
    uppercase: (
      <>
        And provide
        <br /> you with
      </>
    ),
    withIndent: true,
  },
  colored: {
    title: (
      <>
        Our experts
        <br /> create safe cloud based infrastructure
        <br /> solutions
      </>
    ),
    strongText: "life cycle",
    uppercase: "Project estimate",
    link: "#contact-form",
    linkClassName: "cloud-estimate",
    imageDesktop: `${IMG.CLOUD_INFOSTRUCTURE}/scheme-white.svg`,
    imageDesktopWidth: 906,
    imageDesktopHeight: 106,
    withIndent: false,
    industry: "cloud-infrastructure-management",
  },
}

export const SERVICES = {
  text: "",
  items: [
    {
      id: 1,
      title: "Security",
      text: (
        <>
          <p>
            <strong>An application-hardening service </strong>
            <br />
            It includes improving the security of Docker images, libraries, and
            other dependencies. It involves regular updates, patching, and
            minimizing the attack surface by removing unnecessary components.
          </p>
          <p>
            <strong>An infrastructure-hardening service </strong>
            <br />
            Infrastructure hardening involves:
          </p>
          <ul>
            <li>Configuring firewalls;</li>
            <li>Implementing proper access controls;</li>
            <li>Load balancing;</li>
            <li>Other measures to safeguard the overall IT infrastructure.</li>
          </ul>
          <p>
            <strong>Audit compliance service </strong>
            <br />
            We provide End-to-End Security solutions, ensuring your application
            and infrastructure are ready to pass the required security audits.
          </p>
        </>
      ),
    },
    {
      id: 2,
      title: "Cost Effective Solution",
      text: (
        <>
          <p>
            <strong>Cost-optimisation report service </strong>
            <br />
            We analyze your cloud expenses, identify inefficiencies, and provide
            actionable insights to optimize costs without compromising
            performance.
          </p>
          <p>
            <strong>
              Cost-optimized infrastructure configuration service{" "}
            </strong>
            <br />
            We tailor your infrastructure setup for peak efficiency, ensuring
            optimal performance at the lowest possible cost.
          </p>
          <p>
            <strong>Free cloud credit assistance service </strong>
            <br />
            Explore the possibilities of the cloud without the upfront costs. We
            help you secure credits, opening doors to innovative solutions and
            cost-effective cloud exploration.
          </p>
        </>
      ),
    },
    {
      id: 3,
      title: "Performance",
      text: (
        <>
          <p>
            <strong>Scalable infrastructure configuration service </strong>
            <br />
            We fine-tune settings and architecture to handle increased traffic
            and improve performance under load.
          </p>
          <p>
            <strong>Performance analysis report service </strong>
            <br />
            We conduct a comprehensive assessment, pinpointing areas of weakness
            in your system and providing actionable insights for enhanced
            performance.
          </p>
        </>
      ),
    },
    {
      id: 4,
      title: "Compliance",
      text: (
        <>
          <p>
            <strong>AWS security and best practices alignment </strong>
            <br />
            Safeguard your infrastructure by aligning with AWS security
            recommendations and industry best practices. Our service ensures
            that your setup not only meets the stringent security guidelines set
            by AWS but also follows established best practices, fortifying your
            system against potential threats.
          </p>
          <p>
            <strong>Industry-specific compliance </strong>
            <br />
            Our cloud infrastructure engineers customize your infrastructure to
            meet industry-specific compliance standards, ensuring your
            operations align with regulatory requirements. Our service tailors
            configurations to address the unique compliance needs of your
            sector, providing a secure and compliant foundation.
          </p>
        </>
      ),
    },
    {
      id: 5,
      title: "UP-TO-DATE",
      text: (
        <>
          <p>
            <strong>Maintaining high-level security </strong>
            <br />
            We keep your cloud infrastructure security up-to-date by actively
            managing changes, staying ahead of deprecations, and adopting
            advanced approaches to fortify your infrastructure against evolving
            threats.
          </p>
          <p>
            <strong>Maintaining industry standards </strong>
            <br />
            We ensure the infrastructure aligns with industry standards and
            regulations and promptly adapts to any changes or updates in these
            compliance requirements.
          </p>
        </>
      ),
    },
    {
      id: 6,
      title: "High-Availability",
      text: (
        <>
          <p>
            <strong>Maintaining changes </strong>
            <br />
            (our own changes)
          </p>
          <p>
            <strong>Outside changes like traffic </strong>
            <br />
            We design and implement resilient systems, incorporating redundancy,
            failover mechanisms, load balancing, and geographic distribution to
            ensure uninterrupted access, reliability, and optimal performance.
          </p>
        </>
      ),
    },
    {
      id: 7,
      title: "Governance",
      text: (
        <>
          <p>
            <strong>Integrated monitoring </strong>
            <br />
            We seamlessly connect and configure industry-established tools to
            monitor logs and metrics, providing comprehensive insights into your
            infrastructure's performance. It ensures proactive identification of
            issues and adherence to governance policies.
          </p>
          <p>
            <strong>Alerts and notifications </strong>
            <br />
            We customize your alert system, configuring messages and
            notifications to your chosen channels for essential events. This
            personalized approach ensures you stay informed about critical
            infrastructure events, empowering timely responses and effective
            governance.
          </p>
        </>
      ),
    },
  ],
}

export const CLOUDS = [
  { id: 1, text: "We can help your business that" },
  {
    id: 2,
    imageURL: `${IMG.CLOUD_INFOSTRUCTURE}/cloud_1.svg`,
    width: "289",
    height: "179",
    text: "Wants to maintain AWS cloud infrastructure",
  },
  {
    id: 3,
    imageURL: `${IMG.CLOUD_INFOSTRUCTURE}/cloud_1.svg`,
    width: "327",
    height: "203",
    text: "Wants to build a more complex AWS cloud infrastructure",
  },
  {
    id: 4,
    imageURL: `${IMG.CLOUD_INFOSTRUCTURE}/cloud_2.svg`,
    width: "124",
    height: "77",
  },
  {
    id: 5,
    imageURL: `${IMG.CLOUD_INFOSTRUCTURE}/cloud_2.svg`,
    width: "150",
    height: "93",
  },
  {
    id: 6,
    imageURL: `${IMG.CLOUD_INFOSTRUCTURE}/cloud_3.svg`,
    width: "152",
    height: "94",
  },
  {
    id: 7,
    imageURL: `${IMG.CLOUD_INFOSTRUCTURE}/cloud_3.svg`,
    width: "108",
    height: "67",
  },
  {
    id: 8,
    imageURL: `${IMG.CLOUD_INFOSTRUCTURE}/cloud_3.svg`,
    width: "82",
    height: "51",
  },
]
