// extracted by mini-css-extract-plugin
export var billetShadow = "cloud-infrastructure-management-module--billet-shadow--1f943";
export var cloudInfoBlock = "cloud-infrastructure-management-module--cloudInfoBlock--b43a4";
export var cloudsBlock = "cloud-infrastructure-management-module--cloudsBlock--a980c";
export var cloudsWrapper = "cloud-infrastructure-management-module--cloudsWrapper--f3f17";
export var colorScheme__background__grayPale = "cloud-infrastructure-management-module--colorScheme__background__gray-pale--faab9";
export var headline_grid = "cloud-infrastructure-management-module--headline_grid--d675a";
export var icomoon = "cloud-infrastructure-management-module--icomoon--55421";
export var lowercase = "cloud-infrastructure-management-module--lowercase--11b23";
export var solutions = "cloud-infrastructure-management-module--solutions--4cabd";
export var solutions__arrow = "cloud-infrastructure-management-module--solutions__arrow--b6406";
export var solutions__arrowWrapper = "cloud-infrastructure-management-module--solutions__arrowWrapper--2d64b";
export var solutions__table = "cloud-infrastructure-management-module--solutions__table--d43a0";
export var solutions__tableFlex = "cloud-infrastructure-management-module--solutions__tableFlex--4943a";
export var solutions__tableSecurity = "cloud-infrastructure-management-module--solutions__tableSecurity--5fb78";
export var solutions__tableWrapper = "cloud-infrastructure-management-module--solutions__tableWrapper--4f822";
export var transition = "cloud-infrastructure-management-module--transition--16010";
export var transitionBackground = "cloud-infrastructure-management-module--transition-background--2236d";
export var transitionReverse = "cloud-infrastructure-management-module--transition-reverse--c9055";
export var uppercase = "cloud-infrastructure-management-module--uppercase--42b7b";
export var whiteText = "cloud-infrastructure-management-module--whiteText--8bae1";